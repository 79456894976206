import request from '@/utils/request'

export function getAdvanceOrderClientSelectList(data) {
  return request({
    url: '/advance/clientSelect',
    method: 'post',
    data,
  })
}
/**+
 * @网销统计详情
 */
export function getStatisticsOnline(data) {
  return request({
    url: '/shopAdmin/operate/shop-visit-log',
    method: 'post',
    data,
  })
}

/**+
 * @审核预订单
 */
export function reviewAdvance(data) {
  return request({
    url: '/saleAdmin/order-pre/check',
    method: 'post',
    data,
  })
}

// 预订单列表
export function getAdvanceOrderTableList(data) {
  return request({
    url: '/saleAdmin/order-pre/index',
    method: 'post',
    data,
  })
}
export function getAdvanceOrderEditTableList(data) {
  return request({
    url: '/saleAdmin/order-pre/view',
    method: 'post',
    data,
  })
}
export function getOrderDetail(data) {
  return request({
    url: '/saleAdmin/order-pre/view',
    method: 'post',
    data,
  })
}

export function getAdvanceOrderSummaryOrderTableList(data) {
  return request({
    url: '/advance/summaryTableList',
    method: 'post',
    data,
  })
}

// export function test(data) {
//   return request({
//     url: 'http://localhost:8888/addordertable',
//     method: 'get',
//     data,
//   })
// }

// 修改
export function editRow(data) {
  return request({
    url: '/saleAdmin/order-pre/update',
    method: 'post',
    data,
  })
}

// 编辑弹窗中 表格商品下拉获取
export function goodsSelect(data) {
  return request({
    url: '/baseAdmin/goods/window-view-do',
    method: 'post',
    data,
  })
}
// 编辑弹窗中 表格搜索弹窗数据获取
export function goodsSearch(data) {
  return request({
    url: '/baseAdmin/goods/window-view',
    method: 'post',
    data,
  })
}

// 选择商品弹窗  右边表格数据

export function getTableList(data) {
  return request({
    url: '/baseAdmin/goods/window-view-do',
    method: 'post',
    data,
  })
}
//获取出货仓库，退货方式	付款方式	结算方式	下拉
export function getAllSelect(data) {
  return request({
    url: '/saleAdmin/order-pre/create-option',
    method: 'post',
    data,
  })
}
export function getAllSelect2(data) {
  return request({
    url: '/depotAdmin/order/depot',
    method: 'post',
    data,
  })
}

// 送货人下拉
export function driverList(data) {
  return request({
    url: '/baseAdmin/common/deliver-option',
    method: 'post',
    data,
  })
}

// 下单  添加订单
export function addOrder(data) {
  return request({
    url: '/saleAdmin/order-pre/do-bill-order',
    method: 'post',
    data,
  })
}

// 下单并调拔
export function addOrderD(data) {
  return request({
    url: '/saleAdmin/order-pre/do-bill-order-and-allot',
    method: 'post',
    data,
  })
}

// 作废预订单
export function invalidOrder(data) {
  return request({
    url: '/saleAdmin/order-pre/invalid',
    method: 'post',
    data,
  })
}

// 预订单取消派单
export function unSendOrder(data) {
  return request({
    url: '/saleAdmin/order-pre/cancel-accept',
    method: 'post',
    data,
  })
}

// 派单列表 汇总派单提交到右侧表格
export function subRightTable(data) {
  return request({
    url: '/saleAdmin/order-pre/batch-accept-group-by-depot',
    method: 'post',
    data,
  })
}

// 汇总仅派单
export function summarySave(data) {
  return request({
    url: '/saleAdmin/order-pre/batch-accept',
    method: 'post',
    data,
  })
}

// 汇总派单并调拔
export function summarySaveD(data) {
  return request({
    url: '/saleAdmin/order-pre/batch-accept-and-allot',
    method: 'post',
    data,
  })
}

// 预订单派单/派单并调拔
export function sendOrder(data) {
  return request({
    url: '/saleAdmin/order-pre/accept-or-allot',
    method: 'post',
    data,
  })
}

// 预订派送到货
export function sendOrderArrival(data) {
  return request({
    url: '/saleAdmin/order-pre/complete',
    method: 'post',
    data,
  })
}

export function carSelectList(data) {
  return request({
    url: '/baseAdmin/common/depot-car',
    method: 'post',
    data,
  })
}
export function carSelectList2(data) {
  return request({
    url: '/baseAdmin/common/deliver-option',
    method: 'post',
    data,
  })
}

/**
 * ? 导出-销售明细按单据
 */
export function exportSaleDetail(data) {
  return request({
    responseType: 'blob',
    url: '/saleAdmin/sale-form/export-order',
    method: 'post',
    data,
  })
}
/**
 * ? 预订单取消派单前提示
 */
export function beforeUnSendOrder(data) {
  return request({
    url: '/saleAdmin/order-pre/cancel-accept-before',
    method: 'post',
    data,
  })
}
/**
 * @单独保存备注接口  预订单
 */
export function saveRemark(data) {
  return request({
    url: '/saleAdmin/order-pre/update-remark',
    method: 'post',
    data,
  })
}
