var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "更换业务员",
        visible: _vm.showDialog,
        top: "19vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container center" },
        [
          _c(
            "el-select",
            {
              attrs: { clearable: "", placeholder: "请选择业务员" },
              model: {
                value: _vm.staff_id,
                callback: function ($$v) {
                  _vm.staff_id = $$v
                },
                expression: "staff_id",
              },
            },
            _vm._l(_vm.staffSelect, function (i, idx) {
              return _c("el-option", {
                key: idx,
                attrs: { value: i.id, label: i.name },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-bottom center" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }