<template>
  <el-dialog
    v-loading="loading"
    title="更换业务员"
    :visible.sync="showDialog"
    top="19vh"
    :close-on-click-modal="false"
  >
    <div class="orderTest-container center">
      <el-select v-model="staff_id" clearable placeholder="请选择业务员">
        <el-option
          v-for="(i, idx) in staffSelect"
          :key="idx"
          :value="i.id"
          :label="i.name"
        ></el-option>
      </el-select>
    </div>
    <div class="dialog-bottom center">
      <el-button type="primary" @click="handleSubmit">确定</el-button>
      <el-button @click="showDialog = false">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { getStaffList } from '@/api/setPrice'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'ChangeStaff2',
    components: {},
    data() {
      return {
        loading: false,
        showDialog: false,
        ids: [],
        type: 1,
        staffSelect: [],
        staff_id: '',
      }
    },
    computed: {},
    watch: {
      showDialog(v) {
        if (!v) {
          this.staff_id = ''
          this.ids = []
        }
      },
    },
    created() {
      // 获取业务员下拉
      getStaffList().then((res) => {
        this.staffSelect = res.data
      })
    },
    mounted() {},
    methods: {
      async handleSubmit() {
        if (this.staff_id) {
          // this.$emit('staffChange', this.staff_id)
          this.loading = true
          try {
            let res = await postAction(
              '/promoteAdmin/deposit-order/batch-staff',
              {
                type: this.type,
                ids: this.ids,
                staff_id: this.staff_id,
              }
            )
            this.$emit('refresh')
            this.$message.success('操作成功')
            this.showDialog = false
            this.loading = false
          } catch (err) {
            // this.$message.success('操作失败')
            this.loading = false
            this.showDialog = false
          }
        } else {
          this.$message({
            message: '请选择业务员',
            type: 'warning',
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
